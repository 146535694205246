<template>
    <modal class="calculationModel" :isShow="isShow">
        <div class="header">厨师业绩重新核算</div>
        <div class="modal-body">
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">营业日期：</span>
                    <div class="from-input">
                        <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            v-model="businessHours"
                            type="daterange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :default-time="defaultTime"
                        > </el-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 确认</button>
        </div>
        
    </modal>
</template>

<script>
// 厨师业绩重新核算
export default {
    name:'calculationModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            Rpt_Date:'',
            businessHours:[new Date(),new Date()],
            defaultTime:[new Date(),new Date()],
        }
    },
    mounted(){
        this.$nextTick(()=>{
            let userInfo= this.$auth.getUserInfo();
            let Rpt_Date=new Date(userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
           
        });
    },
    methods:{
        //点击确认重新核算 按设置重新核算 厨师名称
        confirm(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            if(!time){
                this.$message.warning("请选择营业日期范围!");
                return;
            }
            const loading = this.$loading({
                text: "数据重新核算中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            /**超时时间2分钟 */
            this.$httpAES.requestConfig({timeout:30*1000*2}).post("Bestech.CloudPos.AgainComputeKitName",{
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("重新核算成功");
                    this.hide();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('重新核算失败：'+e.message);
                console.log('重新核算失败：',e);
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        
    }
}
</script>

<style lang="scss">
@import './calculationModel.scss'
</style>